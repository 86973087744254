import logo from './atalanta.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Atalanta Construction</h1>
        <h6>Custom home builders in Denver, CO</h6>
      </header>
      <div className="App-logo-wrapper">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default App;
